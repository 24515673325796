export interface Option {
  value: string
  label: string
}
export interface GroupOption {
  label: string
  items: Option[]
}
const beaulieu = [
  'beaulieu',
  'beaulieuusa',
  'peerlessusa',
]
const discontinued = [
  'peerless',
  'peerlesscontract',
  'peerlesscontractusa',
  'yourhomestyle',
]
const carpet = [
  'broadloom',
  'carpettile',
  'outdoorcarpet',
]
const vinyl = [
  'luxuryvinyl',
  'engineeredluxuryvinyl',
  'sheetvinyl',
]
const mainSegments = [
  'all',
  'residential',
  'pro',
  'none',
]
export function useRemapOptions({ type, options }: { type: string, options: Ref<string[]> }): ComputedRef<GroupOption[] | Option[] > {
  const { t } = useI18n()
  switch (type) {
    case 'website':
      return computed(() => {
        const result = []
        const beaulieuOptions = options.value
          .filter(option => beaulieu.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (beaulieuOptions.length) {
          result.push({
            label: t('website.beaulieu'),
            items: beaulieuOptions,
          })
        }
        const discontinuedOptions = options.value
          .filter(option => discontinued.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (discontinuedOptions.length) {
          result.push({
            label: t('website.discontinued'),
            items: discontinuedOptions,
          })
        }
        const buyingGroupsOptions = options.value
          .filter(option => !beaulieu.includes(option) && !discontinued.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (buyingGroupsOptions.length) {
          result.push({
            label: t('website.buyinggroups'),
            items: buyingGroupsOptions,
          })
        }
        return result
      })
    case 'category':
      return computed(() => {
        const result = []
        const carpetOptions = options.value
          .filter(option => carpet.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (carpetOptions.length) {
          result.push({
            label: t('category.carpet'),
            items: carpetOptions,
          })
        }
        const otherOptions = options.value
          .filter(option => !carpet.includes(option) && !vinyl.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (otherOptions.length) {
          result.push({
            label: t('category.other'),
            items: otherOptions,
          })
        }
        const vinylOptions = options.value
          .filter(option => vinyl.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (vinylOptions.length) {
          result.push({
            label: t('category.vinyl'),
            items: vinylOptions,
          })
        }
        return result
      })
    case 'segment':
      return computed(() => {
        const result = []
        const mainSegmentsOptions = options.value
          .filter(option => mainSegments.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (mainSegmentsOptions.length) {
          result.push({
            label: t('segment.main'),
            items: mainSegmentsOptions,
          })
        }
        const otherOptions = options.value
          .filter(option => !mainSegments.includes(option))
          .map(option => ({ value: option, label: t(`${type}.${option}`) }))
        if (otherOptions.length) {
          result.push({
            label: t('segment.professionals'),
            items: otherOptions,
          })
        }
        return result
      })
    case 'collection':
      return computed(() => options.value.map(option => ({
        value: option,
        label: option,
      })))
    default:
      return computed(() => options.value.map(option => ({
        value: option,
        label: t(`${type}.${option}`),
      })))
  }
}
