<script lang="ts" setup>
import type { GroupOption } from '~/composables/RemapOptions'

const props = defineProps<{
  name: string
  options: string[]
  label: string
  loading?: boolean
  required?: boolean
}>()
const selected = defineModel<string>('selected')
const options = toRef(props.options)

watch(() => props.options, (newOptions) => {
  options.value = newOptions
})
const remappedOptions = useRemapOptions({ type: props.name, options })
const optionGroupChildren = computed(() => (remappedOptions.value[0] as GroupOption)?.items?.length ? 'items' : undefined)
const optionGroupLabel = computed(() => (remappedOptions.value[0] as GroupOption)?.items?.length ? 'label' : undefined)
</script>

<template>
  <FloatLabel class="w-11/12 lg:w-1/5">
    <Select
      v-model="selected"
      :option-group-label="optionGroupLabel"
      :option-group-children="optionGroupChildren"
      :filter="options.length > 5"
      option-value="value"
      option-label="label"
      show-clear
      :loading="props.loading"
      :disabled="props.loading"
      :input-id="`dd-${props.name}`"
      :options="remappedOptions"
      :pt="{
        optionGroupLabel: {
          class: 'text-zinc-800 dark:text-zinc-500',
        },
      }"
      class="w-full"
    />
    <label class="text-zinc-800 dark:text-white" :for="`dd-${props.name}`">{{ props.label }} <span v-if="props.required" class="text-red-500 text-xs">*</span></label>
  </FloatLabel>
</template>
